import "../styles/pages/invoice.scss"
import React from "react"
import SEO from "../components/seo";
import InvoiceOrderSection from "../components/Invoice/InvoiceOrderSection";
import {graphql} from "gatsby";

const Invoice = ({data, params: {id}}) => {
  const accessoriesList = data.hubbleapi.hubbleAccessoryProducts

  return (
    <div className="invoice-page">
      <SEO title="Invoice" noIndex={true} />
      <InvoiceOrderSection hubbleAccessories={accessoriesList} id={id} />
    </div>
  )
}

export default Invoice

export const query = graphql`
  query {
    hubbleapi {
      hubbleAccessoryProducts{
        title
        handle
        description
        shopifyProductId
        variantId
        price
        productImage1
        productImage2
        id
      }
    }
  }
`
